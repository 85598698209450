import React, { useEffect, useState } from 'react';
import useFields from '../../Hooks/useFields';
import useChemAppStore from '../../stores/chemAppStore';
import { v4 as uuid } from 'uuid'
import {
    FormControl, InputLabel, Select, MenuItem, Box, TextField,
} from "@mui/material/"

function ProductSelector({ edit, sprayBar }) {

    const INITIAL_STATE = {
        product1: '',
        product2: '',
        product3: '',
        rate1: '',
        rate2: '',
        rate3: '',
    }
    const [chemicals, setChemicals] = useState([]);
    const [products, setProducts] = useState([]);
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [chemAppAgreement, setChemAppAgreement] = useChemAppStore(state =>
        [state.chemAppAgreement, state.setChemAppAgreement])

    useEffect(() => {
        resetFormData()
        if (sprayBar) {
            setChemicals([
                { chemicalName: "Archive" },
                { chemicalName: "Stadium" },
                { chemicalName: "Oxiphos" },
                { chemicalName: "Phiticide" },
                { chemicalName: "PUROGENE" },
                { chemicalName: "PUROGENE PRO" },
            ])
        } else {
            setChemicals([
                { chemicalName: "ACETO SHIELD PELLETS(CIPC)" },
                { chemicalName: "SMARTBLOCK" },
                { chemicalName: "AMPLIFY" },
                { chemicalName: "DMN" },
                { chemicalName: "STOROX 2.0" },
                { chemicalName: "PUROGENE" },
                { chemicalName: "PUROGENE PRO" },
                { chemicalName: "SANIDATE 5.0" },
                { chemicalName: "SANIDATE 5.0-DISINFECT" },
                { chemicalName: "SANOQUAT" },
                { chemicalName: "GREEN CLEAN" },
                { chemicalName: "FRESH PACK 100" },
                { chemicalName: "1,4 ZAP" },
                { chemicalName: "GROWTHFUL" },
                { chemicalName: "CITRIC ACID" },
                { chemicalName: "AMINE Z" },
            ])
        }
        if (edit) {
            handleLocal("replaceObj", chemAppAgreement)
        }
        setProducts([{ id: uuid(), pName: "product1", rName: "rate1", title: "1", chemicals: chemicals },
        { id: uuid(), pName: "product2", rName: "rate2", title: "2", chemicals: chemicals },
        { id: uuid(), pName: "product3", rName: "rate3", title: "3", chemicals: chemicals },])
    }, [])


    return (
        <>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
                {products.length > 0 && products.map((product) => (
                    <Box
                        mb={1}
                        key={product.id}
                        width="100%"
                        maxWidth={{ xs: '100%', sm: '33%' }}
                        className={formData[product.pName] !== "" ? "" : "hideForPrint"}
                    >
                        <Box m={1}>
                            <FormControl fullWidth>
                                <InputLabel id={`product${product.title}Label`}>{`Product ${product.title}`}</InputLabel>
                                <Select
                                    id={`product${product.id}`}
                                    name={product.pName}
                                    label={`Product ${product.title}`}
                                    value={formData[product.pName] || ""}
                                    onChange={handleLocal}
                                    onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                    sx={{ width: '100%' }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {chemicals.map((chemObj) => (
                                        <MenuItem
                                            key={chemObj.chemicalName}
                                            value={chemObj.chemicalName || ""}
                                        >
                                            {chemObj.chemicalName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box m={1}>
                            <TextField
                                id={product.rName}
                                name={product.rName}
                                label={`Rate ${product.title}`}
                                value={formData[product.rName] || ''}
                                onChange={handleLocal}
                                onBlur={(e) => setChemAppAgreement(e.target.name, e.target.value)}
                                sx={{ width: '100%' }}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default ProductSelector;