import React from "react";
import { Box, Button, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles';

function SheetCardSorting({ mobile, setSortOrder }) {
    const theme = useTheme()
    const background = theme.palette.background.main

    return <>
        <Divider color={background} />
        <Box display='flex'>
            <Box
                display="flex"
                alignItems="center"
            >
                <Button variant="text"
                    name="sheet_type"
                    onClick={setSortOrder}
                    sx={{ fontSize: `10`, color: background, ml: !mobile && 2 }}
                    style={{ fontWeight: 'bold', textAlign: 'left' }}>
                    Sheet Type
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                sx={{ textAlign: 'center', flex: 1 }}
            >
                <Button variant="text"
                    name="customer"
                    onClick={setSortOrder}
                    sx={{
                        fontSize: `10`,
                        marginRight: '10px',
                        position: `${!mobile ? 'absolute' : ''}`,
                        right: `${!mobile ? '50%' : ''}`,
                        color: background,
                    }}
                    style={{ fontWeight: 'bold' }}>
                    Customer
                </Button>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: 'center' }}
            >{!mobile &&
                <Button variant="text"
                    name="creator"
                    onClick={setSortOrder}
                    sx={{ fontSize: `10`, color: background }}
                    style={{ fontWeight: 'bold' }}>
                    Creator
                </Button>
                }
                <Button variant="text"
                    name="dateServiced"
                    onClick={setSortOrder}
                    sx={{ fontSize: `10`, color: background, ml: !mobile ? 3 : 2, mr: !mobile && 3 }}
                    style={{ fontWeight: 'bold' }}>
                    Date
                </Button>
            </Box>
        </Box >
    </>
}

export default SheetCardSorting;