import React, { useEffect, useState, useContext } from "react";
import SheetsApi from "../../api";
import StageCard from "../StageCard";
import UserContext from "../../Users/UserContext";
import {
    Card, CardContent, Box, Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

function DeptComponent({ dept, company }) {
    const theme = useTheme()
    const { currentUser } = useContext(UserContext);
    const companyPass = company;
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const [stages, setStages] = useState({})

    useEffect(() => {
        let sheetType = ['sheets', 'inspections', 'salesorders', 'refrigeration', 'preseason',
            'chemAppAgreement', 'disinfectantAgreement', 'sprayBarAgreement', 'freshPack'];
        const getSheets = async function () {
            let viewReadyForInvoice = false
            // Decide if user should be fetching sheets within the ready for invoice stage.
            if (currentUser.account_level === 'Admin' || currentUser.dept === 'sales' || currentUser.account_level === 'Regional Admin') viewReadyForInvoice = true;
            return await SheetsApi.getSheets({ company, sheetType, dept, viewReadyForInvoice })
        }
        getSheets().then((sheets) => {
            if (dept === 'sales' && currentUser.dept === 'sales') {
                // If not admin, filter all of the sheets by currentUser so that the sales can only see thier own sheets.
                const sheetsByUser = [];
                for (let stage in sheets) {
                    sheetsByUser[stage] = [];
                    sheets[stage].forEach((sheet) => {
                        if (sheet.creator === currentUser.username) {
                            sheetsByUser[stage].push(sheet);
                        }
                    })
                }
                setStages(sheetsByUser);
            } else setStages(sheets)
        })
    }, [company]);

    function removeSpaces(str) {
        //this function needs to the same as the one in settingsStore / createSettings()
        return str.replace(/[\s-']/g, '');
    };

    function camelCaseToTitle(string) {
        if (!string) return ""
        if (string === 'labs') return 'Storage Management';
        if (string === 'chemApps') return 'Custom Apps'
        const words = string.split(/(?=[A-Z])/);
        const readableString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return readableString;
    };

    return (
        <>
            <Box m={1} sx={{ minWidth: 275 }}>
                <Card sx={{ bgcolor: primary }} elevation={12}>
                    <React.Fragment>
                        <CardContent>
                            <Typography
                                variant="h3"
                                component="div"
                                style={{ color: defaultColor, textAlign: 'center' }}>
                                {camelCaseToTitle(dept)}
                            </Typography>
                            {Object.keys(stages).length > 0 && Object.keys(stages).map((stageName) => {
                                return <StageCard
                                    key={removeSpaces(stageName)}
                                    stageName={stageName}
                                    companyCode={removeSpaces(company)}
                                    toggleId={removeSpaces(stageName + dept)}
                                    stages={stages}
                                    setStages={setStages}
                                    stage={camelCaseToTitle(stageName)}
                                    company={companyPass}
                                />
                            })}
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box>
        </>

    )
}

export default DeptComponent;