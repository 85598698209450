import SheetsApi from "../../../../api";
import useNotification from "../../../../Hooks/useNotification";
class HandleImage {
    static async addAndRemove(allImages, sheetObj, skipDelete) {
        //compares current images to what we have in the db
        let error = false;
        let message = "Successfully added/removed images."
        let oldImages = [];
        if (sheetObj) {
            oldImages = await SheetsApi.getLocalImages({ id: sheetObj.id })
        }
        const imagesToPost = [];
        const signatureToPost = [];
        const imagesToDelete = [];
        const signatureToDelete = [];
        for (let image of allImages) {
            //if new image is here but not in the old images, then add
            // determines weather its a signature or image
            const comparedImage = oldImages.find(img => img.name === image.name);
            if (comparedImage) {
                continue;
            } else {
                image.imageType === "img" ? imagesToPost.push(image) :
                    image.imageType === "receipt" ? imagesToPost.push(image) :
                        image.data !== null && signatureToPost.push(image);
            }
        }

        //Get the sheetType from all images, then filter through all old images to find matching sheetType
        if (!skipDelete) {
            const sheetType = sheetObj.sheet.sheetType
            const imagesOfMatchingSheetType = oldImages.filter(obj => obj.sheetType === sheetType);
            for (let img of imagesOfMatchingSheetType) {
                //Find out if the image should be deleted or saved.
                const comparedImage = allImages.find(image => image.name === img.name)
                if (!comparedImage) {
                    // Remove the image      
                    img.imageType === "signature" && signatureToDelete.push(img)
                    img.imageType === "img" && imagesToDelete.push(img)
                    img.imageType === "receipt" && imagesToDelete.push(img)
                }
            }
        }
        if (imagesToPost.length > 0) {
            imagesToPost.forEach(async (image) => {
                const imageFormData = new FormData();
                if (Object.keys(sheetObj).length > 1) {
                    // imageFormData.append("name", image.name)
                    imageFormData.append("sheetId", sheetObj.id)
                    imageFormData.append("sheetType", sheetObj.sheet.sheetType)
                    imageFormData.append("date", sheetObj.sheet.dateServiced)
                    imageFormData.append("customer", sheetObj.sheet.customerName)
                    imageFormData.append("locationId", sheetObj.sheet.locationId || 0)
                }
                imageFormData.append("imageType", image.imageType === "img" ? "img" : "receipt")
                if (image.imageType === "receipt") {
                    for (let key in image.receiptData) {
                        if (key !== 'selectedUser') {
                            imageFormData.append(key, image.receiptData[key]);
                        } else {
                            imageFormData.append(key, image.receiptData[key].username);
                        }
                    }
                }
                const originalFileName = image.name
                imageFormData.append(image.name, image.file, originalFileName);
                const response = await SheetsApi.uploadImage(imageFormData)
                if (response.error) {
                    error = true;
                    message = 'There was an error when uploading the image. Your image was not saved.'
                }
            })

        }
        if (imagesToDelete.length > 0) {
            await SheetsApi.deleteImage(imagesToDelete)
        }
        if (signatureToPost.length > 0) {
            signatureToPost.forEach(async (signature) => {
                // convert the data string into a file
                const stringOnly = signature.data.replace(/^data:image\/(png|jpeg);base64,/, '')
                const byteCharacters = atob(stringOnly)
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/png' });
                const file = new File([blob], 'image.png', { type: 'image/png' });
                // send file to server via FormData
                const signatureFormData = new FormData();
                signatureFormData.append("sheetId", sheetObj.id)
                signatureFormData.append("sheetType", sheetObj.sheet.sheetType)
                signatureFormData.append("imageType", "signature")
                signatureFormData.append("optionalSig", signature.optionalSig)
                signatureFormData.append(signature.name, file, signature.name)
                await SheetsApi.uploadImage(signatureFormData)
            })
        }
        console.log(signatureToDelete)
        if (signatureToDelete.length > 0) {
            await SheetsApi.deleteImage(signatureToDelete)
        }
        return { message: message, error: error }
    }

    static async setImage(img) {
        //determines if its a signature or photo, then gets image from azure db

        if (img.imageType === 'img' || img.imageType === 'receipt') {
            const image = await SheetsApi.getImages({
                name: img.name,
                imageType: img.imageType
            })
            if (image.error) {
                return {
                    imageType: img.imageType,
                    name: img.name,
                    thumbnail: null,
                    downloadURL: null,
                    file: null,
                    receiptData: img.imageType === 'receipt' ? {
                        id: img.id,
                        creator: img.creator,
                        customer: img.customer,
                        description: img.description,
                        vehicle: img.vehicle,
                        largeJob: img.largeJob,
                        partNumber: img.partNumber,
                        receiptDate: img.receiptDate,
                        receiptDept: img.receiptDept,
                        selectedUser: img.selectedUser,
                        timestamp: img.timestamp,
                        category: img.category,
                        stage: img.stage,
                    } : null
                }
            }
            // convert string data into a file
            const binaryData = atob(image)
            const arrayBuffer = new ArrayBuffer(binaryData.length)
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'image/png' });
            const file = new File([blob], img.name, { type: 'image/png' });
            const thumbnail = await HandleImage.createThumbnail(file);
            const dataURL = URL.createObjectURL(file);
            return {
                imageType: img.imageType,
                sheetType: img.sheetType,
                name: img.name,
                thumbnail: thumbnail,
                downloadURL: dataURL,
                file: file,
                receiptData: img.imageType === 'receipt' ? {
                    id: img.id,
                    creator: img.creator,
                    customer: img.customer,
                    description: img.description,
                    vehicle: img.vehicle,
                    largeJob: img.largeJob,
                    partNumber: img.partNumber,
                    receiptDate: img.receiptDate,
                    receiptDept: img.receiptDept,
                    selectedUser: img.selectedUser,
                    timestamp: img.timestamp,
                    category: img.category,
                    stage: img.stage,
                } : null
            }

        } else if (img.imageType === 'signature') {
            const signature = await SheetsApi.getImages({
                name: img.name,
                imageType: img.imageType,
            })
            return {
                data: 'data:image/png;base64,' + signature,
                name: img.name,
                sheetType: img.sheetType,
            }
        }
    }

    static async createThumbnail(singleFile) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Set the dimensions for the thumbnail
                    const width = 100;
                    const height = (image.height / image.width) * width;

                    canvas.width = width;
                    canvas.height = height;

                    // Draw the image on the canvas
                    ctx.drawImage(image, 0, 0, width, height);
                    // Convert the canvas to a data URL (thumbnail)
                    const thumbnailURL = canvas.toDataURL('image/jpeg');
                    resolve(thumbnailURL);
                };
                image.onerror = (err) => {
                    console.error("There was an error when creating the thumbnail", err)
                }
            };
            reader.readAsDataURL(singleFile);
        });
    }

}

export default HandleImage;